


























































































































import AuthMixins from '../Mixins/Auth';
import AccountMixins from '../Mixins/Account';
import mixins from 'vue-typed-mixins';
import Common from '../Mixins/Common';
import ChangePassword from '@/modals/ChangePassword.vue';

export default mixins(AuthMixins, AccountMixins, Common).extend({
  data() {
    return {
      goToOptions: {
        duration: 500,
        offset: -55,
        easing: 'easeInOutCubic',
      },
      goTogetstarted: {
        duration: 500,
        offset: -90,
        easing: 'easeInOutCubic',
      },
      StaffTitle: this.$i18n.t('Staff').toString(),
      OpenPasswordChange: false,
    };
  },
  methods: {
    openSignInModal() {
      this.$store.commit('openSignInModal', true);
    },
    openSignUpModal() {
      if (
        this.$route.name === 'SignUpSalonDetails' ||
        this.$route.name === 'SignUpPersonalDetails'
      ) {
        const lang = this.$route.params.lang;
        const to = this.$router.resolve({ name: 'SignUpPersonalDetails', params: { lang } });
        this.$router.push(to.location);
      } else {
        this.$store.commit('openSignUpModal', true);
      }
    },
    Logout() {
      this.$store.commit('LoggedOut');
    },
    EditSalonProfile() {
      this.PushToRoute('SalonProfileEdit');
    },
    SalonProfileBilling() {
      this.PushToRoute('SalonProfileBilling');
    },
    ChangePassword() {
      this.OpenPasswordChange = true;
    },
    ClosePasswordChange() {
      this.OpenPasswordChange = false;
    },
  },
  computed: {
    User(): any {
      return this.$store.state.user;
    },
    UserPhoto(): string {
      if (this.User.HasPhoto) {
        return `${this.$store.state.baseUrl}/api/staff/${this.User.Id}/picture/display?ramdom=${this.User.RamdomPhoto}`;
      }
      return '';
    }
  },
  components: {
    ChangePassword,
  },
  watch: {
    User() {
      this.StaffTitle = this.$i18n.t('Staff').toString();
      if (this.User !== undefined) {
        if (this.User.Owner) {
          this.StaffTitle = this.$i18n.t('Owner').toString();
        } else if (this.User.Manager) {
          this.StaffTitle = this.$i18n.t('Manager').toString();
        }
      }
    },
  },
},
);
