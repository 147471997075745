



import Vue from 'vue';
import { Trans } from '@/plugins/trans';
export default Vue.extend({
  name:"LangaugeWrap",
  computed: {
    Language(): any {
      return this.$route.params.lang;
    },
  },
  watch: {
    Language() {
      Trans.changeLanguage(this.Language);
    },
  },
});
