





























































































import { Vue } from 'vue-property-decorator';
import axios from 'axios';

export default Vue.extend({
  data() {
    return {
      form: {
        EmailAddress: '',
        Password: '',
        RememberMe: false,
      },
      LoginError: false,
      Loading: false,
      showPassword: false,
      rules: {
        required: (value: string) => !!value || this.$i18n.t('REQUIRED'),
        emailMatch: () => 'The email and password you entered don\'t match',
      },
    };
  },
  methods: {
    openSignInModal() {
      this.LoginError = false;
      (this.$refs.loginForm as any).reset();
      this.$store.commit('openSignInModal', false);
    },
    openSignUpModal() {
      this.LoginError = false;
      (this.$refs.loginForm as any).reset();
      this.$store.commit('openSignUpModal', true);
    },
    openPasswordResetModal() {
      (this.$refs.loginForm as any).reset();
      this.$store.commit('openPasswordResetModal', true);
    },
    SignInAccount() {
      this.LoginError = false;
      if ((this.$refs.loginForm as any).validate()) {
        this.Loading = true;
        axios
          .post(this.$store.state.baseUrl + `/api/Authentication/login`, this.form)
          .then((response) => {
            this.$cookies.set('RoxRoxAuth', response.headers.authtoken, '20min');
            this.$store
              .dispatch('SetAuthToken', response.headers.authtoken)
              .then(() => {
                this.$store.commit('openSignInModal', false);
                this.$store.dispatch('GetAccount');
                this.$store.dispatch('GetMe');
                this.$store.dispatch('GetProductServiceGroups');
                (this.$refs.loginForm as any).reset();
                this.Loading = false;
                const lang = this.$route.params.lang;
                const to = this.$router.resolve({
                  name: 'dashboard',
                  params: { lang },
                });
                this.$router.push(to.location);
              });
          })
          .catch(() => {
            this.LoginError = true;
            this.Loading = false;
          });
        return;
      }
    },
  },
  computed: {
    ShowSignInModal(): boolean {
      return this.$store.state.ShowSignInModal;
    },
  },
});
