
















import Vue from 'vue';
import { Trans } from '@/plugins/trans';

export default Vue.extend({
  data() {
    return {
      ShowLanguage: false,
    };
  },
  methods: {
    ShowHide() {
      this.ShowLanguage = this.ShowLanguage ? false : true;
    },
    ChangeLanguage(lang: string) {
      this.ShowLanguage = false;
      const to = this.$router.resolve({ params: { lang } });
      this.$router.push(to.location);
    },
  },
  computed: {
    Languages(): any {
      return Trans.supportedLanguages;
    },
  },
});
