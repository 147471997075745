

































































import { Vue } from 'vue-property-decorator';
import axios from 'axios';

export default Vue.extend({
  data() {
    return {
      form: {
        EmailAddress: '',
      },
      LoginError: false,
      Loading: false,
      rules: {
        required: (value: string) => !!value || this.$i18n.t('REQUIRED'),
      },
    };
  },
  methods: {
    openSignInModal() {
      (this.$refs.passwordReset as any).reset();
      this.$store.commit('openSignInModal', true);
    },
    openSignUpModal() {
      (this.$refs.passwordReset as any).reset();
      this.$store.commit('openSignUpModal', true);
    },
    openPasswordResetModal() {
      (this.$refs.passwordReset as any).reset();
      this.$store.commit('openPasswordResetModal', false);
    },
    SignInAccount() {
      this.LoginError = false;
      if ((this.$refs.passwordReset as any).validate()) {
        this.Loading = true;
        axios
          .post(
            this.$store.state.baseUrl + '/api/requestpasswordtoken',
            this.form,
          )
          .then(() => {

          })
          .catch(() => {
            this.LoginError = true;
            this.Loading = false;
          });
        return;
      }
    },
  },
  computed: {
    ShowPasswordResetModal(): boolean {
      return this.$store.state.ShowPasswordResetModal;
    },
  },
});
