










import Vue from 'vue';
import RoxRoxHeader from '@/components/RoxRoxHeader.vue';
import RoxRoxFooter from '@/components/RoxRoxFooter.vue';

export default Vue.extend({
  components: {
    RoxRoxHeader,
    RoxRoxFooter,
  },
  mounted() {
    const AuthToken = this.$cookies.get('RoxRoxAuth');
    if (AuthToken != null) {
      this.$store
        .dispatch('SetAuthToken', AuthToken)
        .then(() => { });
    }
  }
});
