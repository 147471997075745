




























import mixins from 'vue-typed-mixins';
import Login from '@/modals/LogIn.vue';
import ResetPassword from '@/modals/ResetPassword.vue';
import SignUp from '@/modals/SignUp.vue';
import CurrencyChange from '@/modals/CurrencyChange.vue';
import LanguageChange from '@/modals/LanguageChange.vue';
import AuthMixins from '../Mixins/Auth';

export default mixins(AuthMixins).extend({
  data() {
    return {
      modalBackingStyleObject: [] as string[],
    };
  },
  components: {
    Login,
    ResetPassword,
    SignUp,
    CurrencyChange,
    LanguageChange,
  },
  methods: {
    // displayModalBack() {
    //   if (
    //     this.ShowSignInModal ||
    //     this.ShowSignUpModal ||
    //     this.ShowPasswordResetModal
    //   ) {
    //     const i = document.querySelector('html');
    //     if (i !== null) {
    //       i.style.overflow = 'hidden';
    //     }
    //   } else {
    //     const i = document.querySelector('html');
    //     if (i !== null) {
    //       i.style.overflow = 'null';
    //     }
    //   }
    // },
  },
  computed: {
    ShowSignInModal(): boolean {
      return this.$store.state.ShowSignInModal;
    },
    ShowPasswordResetModal(): boolean {
      return this.$store.state.ShowPasswordResetModal;
    },
    ShowSignUpModal(): boolean {
      return this.$store.state.ShowSignUpModal;
    },
  },
  // watch: {
  //   ShowSignInModal() {
  //     this.displayModalBack();
  //   },
  //   ShowSignUpModal() {
  //     this.displayModalBack();
  //   },
  //   ShowPasswordResetModal() {
  //     this.displayModalBack();
  //   },
  // },
  mounted() {
    if (!this.$store.state.currencies.length) {
      this.$store.dispatch('getCurrencies');
    }
    if (!this.$store.state.currencyPackages.length) {
      this.$store.dispatch('getPackages');
    }
    if (!this.$store.state.CountryList.length) {
      this.$store.dispatch('getCountries');
    }
    if (!this.$store.state.TimeZone.length) {
      this.$store.dispatch('getTimeZone');
    }
  },
});
