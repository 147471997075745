
















import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      ShowCurrency: false,
    };
  },
  methods: {
    ShowHide() {
      this.ShowCurrency = this.ShowCurrency ? false : true;
    },
    ChangeCurrencies(i: string) {
      this.ShowCurrency = false;
      this.$store.dispatch('getPackages', i);
    },
  },
  computed: {
    currencies(): any {
      return this.$store.state.currencies;
    },
  },
});
