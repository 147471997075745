
































































































































































import { Vue } from 'vue-property-decorator';
import { AsYouType } from 'libphonenumber-js';
import { phoneNumber, nonNumeric } from '@/constants/trans';

export default Vue.extend({
  data() {
    return {
      form: {
        FirstName: '',
        Surname: '',
        EmailAddress: '',
        MobilePhoneNumber: '',
        Password: '',
        CountryId: '',
        OwnerCountryCode: '',
        OwnerNumber: '',
      },
      passwordConfirmation: '',
      Loading: false,
      SelectCountry: '' as any,
      lastValue: '',
      showPassword: false,
      showRepeatPassword: false,
      rules: {
        required: (v: string) => !!v || this.$i18n.t('REQUIRED'),
        email: (v: string) =>
          /.+@.+/.test(v) || this.$i18n.t('EMAIL_VALID'),
        passwordRegex: (v: string) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{3,}$/.test(v) ||
          this.$i18n.t('PASSWORD_PATTERN'),
        passwordmin: (v: string) => {
          if (v == undefined && v == null) {
            return false;
          }
          return v.length >= 6 || this.$i18n.t('PASSWORD_MIN');

        },
        phoneNumberRegex: (v: string) =>
          phoneNumber.test(v) || this.$i18n.t("PhonePattern"),
        nonNumeric: (v: string) =>
          nonNumeric.test(v) || this.$i18n.t('INVALIDCHAR')
      },
    };
  },
  props: {
    LoadValues: Boolean,
    ClearVales: Boolean,
    ShowLinkToSignIn: Boolean,
  },
  methods: {
    passwordMatchError() {
      return this.form.Password === this.passwordConfirmation
        ? ''
        : this.$i18n.t('REPEAT_PASSWORD_MATCH');
    },
    createAccount() {
      if ((this.$refs.signUpPersonalDetails as any).validate()) {
        this.refreshPhone(this.form.OwnerNumber);
        this.$store.commit('InitalCreateAccount', this.form);
        this.$store.commit('openSignUpModal', false);
        (this.$refs.signUpPersonalDetails as any).reset();
        const lang = this.$route.params.lang;
        const to = this.$router.resolve({ name: 'SignUpSalonDetails', params: { lang } });
        this.$router.push(to.location);
      }
    },
    onInput(e: any) {
      this.refreshPhone(e);
    },
    refreshPhone(value: string) {
      if (this.form.OwnerCountryCode == '' || this.form.OwnerCountryCode == null || this.form.OwnerCountryCode == 'undefined') {
        return;
      }

      let countryOption = "" as any;
      if (this.form.OwnerCountryCode != null) {
        countryOption = this.form.OwnerCountryCode.toUpperCase() as any;
      }

      if (countryOption === 'UK') {
        countryOption = 'GB';
      }
      const ASYou = new AsYouType(countryOption);
      const formattedPhone = ASYou.input(value);

      if (formattedPhone !== this.lastValue) {
        this.lastValue = formattedPhone;
        this.form.OwnerNumber = formattedPhone;
        var number = ASYou.getNumber();
        if (number !== undefined) {
          this.form.MobilePhoneNumber = number.number.toString();
        }
        else {
          this.form.MobilePhoneNumber = value;
        }
      }
    },
    openSignInModal() {
      (this.$refs.signUpPersonalDetails as any).reset();
      this.$store.commit('openSignInModal', true);
    },
  },
  computed: {
    CountryOptions(): any {
      return this.$store.state.CountryList;
    },
    ShowSignUpModal(): boolean {
      return this.$store.state.ShowSignUpModal;
    },
    OwnerCountryCode(): string {
      return this.form.OwnerCountryCode;
    }
  },
  watch: {
    SelectCountry() {
      this.form.CountryId = this.SelectCountry;
      this.$store.dispatch('getCountryStates', this.SelectCountry);
    },
    ShowSignUpModal() {
      if (this.ShowSignUpModal && this.ClearVales) {
        (this.$refs.signUpPersonalDetails as any).reset();
      }
    },
    OwnerCountryCode() {
      this.refreshPhone(this.form.OwnerNumber);
      if (this.form.CountryId === undefined && this.form.CountryId == null && this.form.CountryId === '') {
        this.form.CountryId = this.OwnerCountryCode;
      }
    }
  },
  mounted() {
    if (this.LoadValues) {
      this.form.FirstName = this.$store.state.CreateAccount.OwnerFirstName;
      this.form.Surname = this.$store.state.CreateAccount.OwnerSurName;
      this.form.EmailAddress = this.$store.state.CreateAccount.OwnerEmail;
      this.form.OwnerNumber = this.$store.state.CreateAccount.OwnerNumber;
      this.form.CountryId = this.$store.state.CreateAccount.CountryId;
      this.SelectCountry = this.$store.state.CreateAccount.CountryId;
      this.form.OwnerCountryCode = this.$store.state.CreateAccount.OwnerCountryCode;
      this.refreshPhone(this.form.OwnerNumber);
    }
  },
});
