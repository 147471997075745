import mixins from 'vue-typed-mixins';
import { AsYouType } from 'libphonenumber-js';
import AccountMixins from './Account';
import { Trans } from '@/plugins/trans';

export default mixins(AccountMixins).extend({
    data() {
        return {
            GenderOptions: [
                { Title: this.$i18n.t('Male'), value: 'Male' },
                { Title: this.$i18n.t('Female'), value: 'Female' },
                { Title: this.$i18n.t('Unspecified'), value: 'Unspecified' },
            ],
        };
    },
    methods: {
        refreshPhone(value: string): string {
            if (
                this.Account.CountryId === '' ||
                typeof this.Account.CountryId === 'undefined'
            ) {
                return value;
            }
            let countryOption = this.Account.CountryId.toUpperCase() as any;
            if (countryOption === 'UK') {
                countryOption = 'GB';
            }
            const ASYou = new AsYouType(countryOption);
            const formattedPhone = ASYou.input('+' + value.replace('+', ''));
            const numberDetails = ASYou.getNumber();
            if (numberDetails !== undefined) {
                if (numberDetails.country === countryOption) {
                    return numberDetails.format('NATIONAL');
                } else {
                    return formattedPhone;
                }
            } else {
                return '+' + value.replace('+', '');
            }
        },
        PushToRouteWithId(name: string, id: string) {
            const lang = this.$route.params.lang;
            const to = this.$router.resolve({
                name,
                params: { lang, id },
            });
            this.$router.push(to.location);
        },
        PushToRoute(name: string) {
            const lang = this.$route.params.lang;
            const to = this.$router.resolve({
                name,
                params: { lang },
            });
            this.$router.push(to.location);
        },
        TouchCookie() {
            const AuthToken = this.$cookies.get('RoxRoxAuth');
            if (AuthToken != null) {
                this.$cookies.set('RoxRoxAuth', AuthToken, '20min');
            }
        },
        back() {
            this.$router.go(-1);
        },
        DisplayGender(Gender: string): string {
            switch (Gender) {
                case 'Male':
                    return this.$i18n.t('Male').toString();
                case 'Female':
                    return this.$i18n.t('Female').toString();
                case 'Unspecified':
                    return this.$i18n.t('Unspecified').toString();
            }
            return '';
        },
        getIsoLang() {
            const lang = window.navigator.language || Trans.defaultLanguage;
            const langshort = lang.split('-')[0];
            if (Trans.supportedLanguages.indexOf(langshort) !== -1) {
                return lang;
            }
            return this.$route.params.lang;
        },
    },
    computed: {
        CountryOptions(): any {
            return this.$store.state.CountryList;
        },
        HasStates(): boolean {
            return this.GetStates.length !== 0;
        },
        GetStates(): any {
            return this.$store.state.CountryStates;
        },
    },
});
