import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'
import { CreateAccount } from '../Models/CreateAccount';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        baseUrl: 'https://api.uat.roxrox.com',
        //baseUrl: 'https://localhost:5001',
        //baseUrl: 'https://ilminster.asuscomm.com:5001',
        //baseUrl: 'https://ilminster.asuscomm.com:44383',
        //baseUrl: 'https://192-168-2-223.nip.io:44383', // 'https://localhost:44383',https://192.168.2.223:44383
        ShowSignInModal: false,
        ShowSignUpModal: false,
        ShowPasswordResetModal: false,
        ShowTermsModal: false,
        isAuthenticated: false,
        selectedCurrency: 'aud',
        currencies: [],
        currencyPackages: [],
        currencyPackagesLoaded: false,
        authToken: '',
        CountryStates: [],
        CountryList: [],
        CountryOptions: [],
        TimeZone: [],
        StatesLoadedCountry: '',
        user: '' as any,
        CreateAccount: new CreateAccount(),
        Account: '' as any,
        HasAccount: false,
        Customers: [] as any[],
        CustomersLoading: false,
        StaffLoading: false,
        Staff: [] as any[],
        ServicesLoading: false,
        Services: [] as any[],
        ProductsLoading: false,
        Products: [] as any[],
        ProductServiceGroupsLoading: false,
        ProductServiceGroups: [] as any[],
        TaxRateLoading: false,
        TaxRate: [] as any[],
        ShopHoursLoading: true,
        NormalShopHours: [] as any[],
        LocalholidaysLoading: true,
        Localholidays: [] as any[],
        ExceptionShopHoursLoading: true,
        ExceptionShopHours: [] as any[],
        Facility: [] as any[],
        FacilityLoading: false,
        AccpetedPaymentMethod: [] as any[],
        AccpetedPaymentMethodLoading: false,
        BillingHistory: [] as any,
        BillingLoading: false,
    },
    mutations: {
        openSignInModal(state, payload) {
            if (payload && state.isAuthenticated) {
                return;
            }
            state.ShowSignInModal = payload;
            if (payload) {
                state.ShowSignUpModal = false;
                state.ShowPasswordResetModal = false;
            }
        },
        openSignUpModal(state, payload) {
            if (payload && state.isAuthenticated) {
                return;
            }
            state.ShowSignUpModal = payload;
            if (payload) {
                state.ShowSignInModal = false;
                state.ShowPasswordResetModal = false;
            }
        },
        openTermsModal(state, payload) {
            if (payload && state.isAuthenticated) {
                return;
            }
            state.ShowTermsModal = payload;
        },
        openPasswordResetModal(state, payload) {
            if (payload && state.isAuthenticated) {
                return;
            }
            state.ShowPasswordResetModal = payload;
            if (payload) {
                state.ShowSignInModal = false;
                state.ShowSignUpModal = false;
            }
        },
        UpdateAuthToken(state, payload) {
            state.authToken = payload;
            if (payload !== null) {
                state.isAuthenticated = true;
            } else {
                state.isAuthenticated = false;
                state.user = null;
            }
        },
        UpdatePackages(state, payload) {
            state.currencyPackages = payload;
            state.currencyPackagesLoaded = true;
        },
        UpdateCountryList(state, payload) {
            state.CountryList = payload;
            payload.forEach((element: any) => {
                element.DialCodeOption = '+' + element.DialCode + ' (' + element.Name + ')';
                element.DialCodeString = '+' + element.DialCode;
            });
        },
        UpdateCurrenies(state, payload) {
            state.currencies = payload;
        },
        UpdateTimeZone(state, payload) {
            state.TimeZone = payload;
        },
        UpdateSelectedCurrency(state, payload) {
            state.currencyPackagesLoaded = false;
            state.selectedCurrency = payload;
        },
        InitalCreateAccount(state, payload) {
            state.CreateAccount.OwnerEmail = payload.EmailAddress;
            state.CreateAccount.OwnerFirstName = payload.FirstName;
            state.CreateAccount.OwnerSurName = payload.Surname;
            state.CreateAccount.OwnerMobile = payload.MobilePhoneNumber;
            state.CreateAccount.OwnerPassword = payload.Password;
            state.CreateAccount.CountryId = payload.CountryId;
            state.CreateAccount.OwnerCountryCode = payload.OwnerCountryCode;
            state.CreateAccount.OwnerNumber = payload.OwnerNumber;
        },
        CreateAccountSalon(state, payload) {
            state.CreateAccount.LegalName = payload.LegalName;
            state.CreateAccount.TradingName = payload.TradingName;
            state.CreateAccount.TaxNumber = payload.TaxNumber;
            state.CreateAccount.Email = payload.Email;
            state.CreateAccount.PhoneNumber = payload.PhoneNumber;
            state.CreateAccount.TimeZoneId = payload.TimeZoneId;
            state.CreateAccount.CustomerCurrencyId = payload.CustomerCurrencyId;
            state.CreateAccount.Address1 = payload.Address1;
            state.CreateAccount.Address2 = payload.Address2;
            state.CreateAccount.City = payload.City;
            state.CreateAccount.State = payload.State;
            state.CreateAccount.PostCode = payload.PostCode;
            state.CreateAccount.PhoneNumberCountryCode = payload.PhoneNumberCountryCode;
        },
        CreateAccountSetPackage(state, payload) {
            state.CreateAccount.Optional.Frequency = payload.CurrencyPaymentFrequency;
            state.CreateAccount.PackageId = payload.PackageId;
        },
        UpdateLoadedStateForCountry(state, payload) {
            state.CountryStates = payload;
        },
        CreateAccountSetStripePayment(state, payload) {
            state.CreateAccount.Optional.StripeTokenId = payload;
            state.CreateAccount.PaymentProviderId = 2;
        },
        UpdateAccount(state, payload) {
            state.Account = payload;
            state.HasAccount = true;
        },
        UpdateUser(state, payload) {
            state.user = payload;
        },
        UpdateCustomers(state, payload) {
            state.Customers = payload;
        },
        UpdateSingleCustomer(state, payload) {
            state.Customers = state.Customers.filter((value: any) => {
                // debugger;
                return value.ExternalId !== payload.ExternalId;
            });
            state.Customers.push(payload);
        },
        AddCustomers(state, payload) {
            state.Customers.push(payload);
        },
        AddStaff(state, payload) {
            state.Staff.push(payload);
        },
        UpdateStaff(state, payload) {
            state.Staff = payload;
        },
        UpdateSingleStaff(state, payload) {
            state.Staff = state.Staff.filter((value: any) => {
                // debugger;
                return value.ExternalId !== payload.ExternalId;
            });
            state.Staff.push(payload);
        },
        UpdateServices(state, payload) {
            state.Services = payload;
        },
        UpdateSingleService(state, payload) {
            state.Services = state.Services.filter((value: any) => {
                // debugger;
                return value.ExternalId !== payload.ExternalId;
            });
            state.Services.push(payload);
        },
        AddServices(state, payload) {
            state.Services.push(payload);
        },
        UpdateFacility(state, payload) {
            state.Facility = payload;
        },
        AddFacility(state, payload) {
            state.Facility.push(payload);
        },
        UpdateSingleFacility(state, payload) {
            state.Facility = state.Facility.filter((value: any) => {
                return value.ExternalId !== payload.ExternalId;
            });
            state.Facility.push(payload);
        },
        UpdateAccpetedPaymentMethod(state, payload) {
            state.AccpetedPaymentMethod = payload;
        },
        AddAccpetedPaymentMethod(state, payload) {
            state.AccpetedPaymentMethod.push(payload);
        },
        UpdateSingleAccpetedPaymentMethod(state, payload) {
            state.AccpetedPaymentMethod = state.AccpetedPaymentMethod.filter((value: any) => {
                return value.Id !== payload.Id;
            });
            state.AccpetedPaymentMethod.push(payload);
        },
        UpdateProductServiceGroups(state, payload) {
            state.ProductServiceGroups = payload;
        },
        AddProductServiceGroup(state, payload) {
            state.ProductServiceGroups.push(payload);
        },
        UpdateSingleProductServiceGroup(state, payload) {
            state.ProductServiceGroups = state.ProductServiceGroups.filter((value: any) => {
                // debugger;
                return value.Id !== payload.Id;
            });
            state.ProductServiceGroups.push(payload);
        },
        UpdateTaxRate(state, payload) {
            if (payload == null ||
                payload === 0
            ) {
                payload.forEach((element: any) => {
                    if (element.AccountDefaultRate) {
                        element.DisplayDefault = `${payload.Rate}%`;
                    }
                });
            }
            state.TaxRate = payload;
        },
        AddTaxRate(state, payload) {
            state.TaxRate.push(payload);
        },
        UpdateSingleTaxRate(state, payload) {
            state.TaxRate = state.TaxRate.filter((value: any) => {
                // debugger;
                return value.Id !== payload.Id;
            });
            state.TaxRate.push(payload);
        },
        AddProduct(state, payload) {
            state.Products.push(payload);
        },
        UpdateProducts(state, payload) {
            state.Products = payload;
        },
        UpdateSingleProduct(state, payload) {
            state.Products = state.Products.filter((value: any) => {
                // debugger;
                return value.ExternalId !== payload.ExternalId;
            });
            state.Products.push(payload);
        },
        UpdateShopHours(state, payload) {
            state.NormalShopHours = payload;
        },
        UpdateLocalholidays(state, payload) {
            state.Localholidays = payload;
        },
        UpdateExceptionShopHours(state, payload) {
            state.ExceptionShopHours = payload;
        },
        UpdatePaymentHistory(state, payload) {
            state.BillingHistory = payload;
        },
        UpdateSingleExceptionHour(state, payload) {
            state.ExceptionShopHours = state.ExceptionShopHours.filter((value: any) => {
                // debugger;
                return value.Date !== payload.Date;
            });
            state.ExceptionShopHours.push(payload);
        },
        LoggedOut(state) {
            state.isAuthenticated = false;
            state.authToken = '';
            state.Customers = [];
            state.Account = '';
            state.Staff = [];
            state.Services = [];
            state.Products = [];
            state.ProductServiceGroups = [];
        },
    },
    actions: {
        getCurrencies(context) {
            return new Promise((resolve, reject) => {
                axios.get(context.state.baseUrl + '/api/currencies/all')
                    .then((response) => {
                        // JSON responses are automatically parsed.
                        context.commit('UpdateCurrenies', response.data.sort((a: any, b: any) => {
                            const x = a.Name.toLowerCase();
                            const y = b.Name.toLowerCase();
                            if (x < y) {
                                return -1;
                            }
                            if (x > y) {
                                return 1;
                            }
                            return 0;
                        }));
                        resolve(null);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        getPackages(context, payload) {
            if (payload != null) {
                context.commit('UpdateSelectedCurrency', payload);
            }
            return new Promise((resolve, reject) => {
                axios.get(context.state.baseUrl + '/api/currencies/' + context.state.selectedCurrency + '/packages')
                    .then((response) => {
                        // JSON responses are automatically parsed.
                        const packages = response.data.sort((a: any, b: any) => {
                            const x = a.MonthlyPrice;
                            const y = b.NameMonthlyPrice;
                            if (x < y) {
                                return -1;
                            }
                            if (x > y) {
                                return 1;
                            }
                            return 0;
                        });
                        context.commit('UpdatePackages', packages);
                        resolve(null);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        SetAuthToken(context, payload) {
            alert("test");
            context.commit('UpdateAuthToken', payload);
        },
        getCountries(context) {
            return new Promise((resolve, reject) => {
                axios.get(context.state.baseUrl + '/api/Countries')
                    .then((response) => {
                        // JSON responses are automatically parsed.
                        const CountryList = response.data.sort((a: any, b: any) => {
                            const x = a.Name.toLowerCase();
                            const y = b.Name.toLowerCase();
                            if (x < y) {
                                return -1;
                            }
                            if (x > y) {
                                return 1;
                            }
                            return 0;
                        });
                        context.commit('UpdateCountryList', CountryList);
                        resolve(null);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        getTimeZone(context) {
            return new Promise((resolve, reject) => {
                axios.get(context.state.baseUrl + '/api/TimeZones')
                    .then((response) => {
                        context.commit('UpdateTimeZone', response.data);
                        resolve(null);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        getCountryStates(context, payload) {
            return new Promise((resolve, reject) => {
                if (context.state.StatesLoadedCountry !== payload && payload !== null && typeof payload !== 'undefined') {
                    axios.get(context.state.baseUrl + `/api/Countries/${payload}/states`)
                        .then((response) => {
                            context.state.StatesLoadedCountry = payload;
                            context.commit('UpdateLoadedStateForCountry', response.data);
                            resolve(null);
                        })
                        .catch((e) => {
                            reject(e);
                        });
                }
            },
            );
        },
        postCreateAccount(context, payload) {
            context.state.CreateAccount.CurrencyId = context.state.selectedCurrency;
            context.state.CreateAccount.LanguageId = payload;
            context.state.CreateAccount.OwnerMobile = context.state.CreateAccount.OwnerMobile.replace('+', '');
            context.state.CreateAccount.PhoneNumber = context.state.CreateAccount.PhoneNumber.replace('+', '');
            return new Promise((resolve, reject) => {
                axios.post(context.state.baseUrl + '/api/Accounts', context.state.CreateAccount)
                    .then((response) => {
                        context.commit('UpdateAuthToken', response.headers.authtoken);
                        resolve(null);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            },

            );
        },
        postCreatePaypalAccount(context, payload) {
            context.state.CreateAccount.PaymentProviderId = 1;
            context.state.CreateAccount.Optional.StripeTokenId = '';
            context.state.CreateAccount.CurrencyId = context.state.selectedCurrency;
            context.state.CreateAccount.LanguageId = payload;
            context.state.CreateAccount.OwnerMobile = context.state.CreateAccount.OwnerMobile.replace('+', '');
            context.state.CreateAccount.PhoneNumber = context.state.CreateAccount.PhoneNumber.replace('+', '');
            return new Promise((resolve, reject) => {
                axios.post(context.state.baseUrl + '/api/Accounts', context.state.CreateAccount)
                    .then((response) => {
                        context.commit('UpdateAuthToken', response.headers.authtoken);
                        resolve(response.data.PaypalRedirect);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            },
            );
        },
        GetAccount(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/accounts/myaccount', dispatch: 'UpdateAccount', loading: (result: boolean): void => { return; },
                });
        },
        PutAccount(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/accounts/${payload.ExternalId}`,
                    dispatch: 'UpdateAccount', payload, loading: (result: boolean): void => { return; },
                });
        },
        DeletePayment(context) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/accounts/myaccount/payment`,
                    dispatch: 'GetAccount', loading: (result: boolean): void => { return; },
                });
        },
        UpdateStripCardAccount(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/accounts/myaccount/payment`,
                    dispatch: 'UpdateAccount', payload, loading: (result: boolean): void => { return; },
                });
        },
        UpdateAccountPackage(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/accounts/${context.state.Account.ExternalId}/package`,
                    dispatch: 'UpdateAccount', payload, loading: (result: boolean): void => { return; },
                });
        },
        GetMe(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/staff/me', dispatch: 'UpdateUser', loading: (result: boolean): void => { return; },
                });
        },

        PostCustomer(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/Customers`, dispatch: 'AddCustomers', payload, loading: (result: boolean): void => {
                        context.state.CustomersLoading = result;
                    },
                });
        },
        GetCustomer(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/Customers', dispatch: 'UpdateCustomers', loading: (result: boolean): void => {
                        context.state.CustomersLoading = result;
                    },
                });
        },
        PutCustomer(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/Customers/${payload.ExternalId}`,
                    dispatch: 'UpdateSingleCustomer', payload, loading: (result: boolean): void => {
                        context.state.CustomersLoading = result;
                    },
                });
        },
        DeleteCustomer(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/Customers/${payload}`, dispatch: 'GetCustomer', loading: (result: boolean): void => {
                        context.state.CustomersLoading = result;
                    },
                });
        },
        PostStaff(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/staff`, dispatch: 'AddStaff', payload, loading: (result: boolean): void => {
                        context.state.StaffLoading = result;
                    },
                });
        },
        PostPhoto(context, payload) {
            const formData = new FormData();

            /*
                Add the form data we need to submit
            */
            formData.append('file', payload.file, 'test.png');
            return new Promise((resolve, reject) => {
                axios.post(`${context.state.baseUrl}/api/${payload.type}/${payload.id}/pictures`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data', 'authtoken': context.state.authToken,
                        },
                    },
                ).then((response) => {
                    resolve(response);
                }).catch((e) => {
                    reject(e);
                });
            });
        },
        GetStaffPhoto(context, payload) {
            return new Promise((resolve, reject) => {
                axios.get(`${context.state.baseUrl}/api/staff/${payload}/picture/display`, {
                    headers: {
                        authtoken: context.state.authToken,
                    },
                },
                ).then((response) => {
                    resolve(response);
                })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        SetStaffPhotoTrue(context, payload) {
            const staff = context.state.Staff.find((x) => x.Id === payload);
            staff.HasPhoto = true;
            const offset = Math.floor(Math.random() * 100) + 1;
            staff.RamdomPhoto = offset;
            context.state.user.HasPhoto = true;
            context.state.user.RamdomPhoto = offset;
        },
        SetAccountPhotoTrue(context, payload) {
            context.state.Account.PhotoId = payload;
            context.state.Account.HasPhoto = true;
        },
        DeleteAccountPhoto(context, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(`${context.state.baseUrl}/api/accounts/myaccount/picture`,
                    { headers: { authtoken: context.state.authToken } })
                    .then(() => {
                        context.state.Account.HasPhoto = false;
                        context.state.Account.PhotoId = '';
                        resolve(null);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },

        DeleteStaffPhoto(context, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(`${context.state.baseUrl}/api/staff/${payload}/picture`,
                    { headers: { authtoken: context.state.authToken as string } })
                    .then(() => {
                        const staff = context.state.Staff.find((x) => x.Id === payload);
                        staff.HasPhoto = false;
                        context.state.user.HasPhoto = false;
                        resolve(null);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        GetStaff(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/Staff', dispatch: 'UpdateStaff', loading: (result: boolean): void => {
                        context.state.StaffLoading = result;
                    },
                });
        },
        PutStaff(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/staff/${payload.ExternalId}`,
                    dispatch: 'UpdateSingleStaff', payload, loading: (result: boolean): void => {
                        context.state.StaffLoading = result;
                    },
                });
        },
        DeleteStaff(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/staff/${payload}`, dispatch: 'GetStaff', loading: (result: boolean): void => {
                        context.state.StaffLoading = result;
                    },
                });
        },
        GetServices(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/Services', dispatch: 'UpdateServices', loading: (result: boolean): void => {
                        context.state.ServicesLoading = result;
                    },
                });
        },
        PostService(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/Services`, dispatch: 'AddServices', payload, loading: (result: boolean): void => {
                        context.state.ServicesLoading = result;
                    },
                });
        },
        PutService(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/Services/${payload.Id}`,
                    dispatch: 'UpdateSingleService', payload, loading: (result: boolean): void => {
                        context.state.ServicesLoading = result;
                    },
                });
        },
        DeleteService(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/Services/${payload}`, dispatch: 'GetServices', loading: (result: boolean): void => {
                        context.state.ServicesLoading = result;
                    },
                });
        },
        GetProducts(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/Products', dispatch: 'UpdateProducts', loading: (result: boolean): void => {
                        context.state.ProductsLoading = result;
                    },
                });
        },
        PostProduct(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/products`, dispatch: 'AddProduct', payload, loading: (result: boolean): void => {
                        context.state.ProductsLoading = result;
                    },
                });
        },
        PutProduct(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/products/${payload.ExternalId}`,
                    dispatch: 'UpdateSingleProduct', payload, loading: (result: boolean): void => {
                        context.state.ProductsLoading = result;
                    },
                });
        },
        DeleteProduct(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/products/${payload}`, dispatch: 'GetProducts', loading: (result: boolean): void => {
                        context.state.ProductsLoading = result;
                    },
                });
        },
        GetProductServiceGroups(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/ServiceGroups', dispatch: 'UpdateProductServiceGroups', loading: (result: boolean): void => {
                        context.state.ProductServiceGroupsLoading = result;
                    },
                });
        },
        PostProductServiceGroup(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/ServiceGroups`, dispatch: 'AddProductServiceGroup', payload, loading: (result: boolean): void => {
                        context.state.ProductServiceGroupsLoading = result;
                    },
                });
        },
        PutProductServiceGroup(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/ServiceGroups/${payload.Id}`,
                    dispatch: 'UpdateSingleProductServiceGroup', payload, loading: (result: boolean): void => {
                        context.state.ProductServiceGroupsLoading = result;
                    },
                });
        },
        DeleteProductServiceGroup(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/ServiceGroups/${payload.GroupDelete}?deleteChild=false&newGroupId=${payload.MoveToGroup}`,
                    dispatch: 'GetProductServiceGroups', loading: (result: boolean): void => {
                        if (result) {
                            context.dispatch('GetProducts');
                        }
                        context.state.ProductsLoading = result;
                    },
                });
        },
        GetTaxRates(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/taxrate', dispatch: 'UpdateTaxRate', loading: (result: boolean): void => {
                        context.state.TaxRateLoading = result;
                    },
                });
        },
        PostTaxRate(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/taxrate`, dispatch: 'AddTaxRate', payload, loading: (result: boolean): void => {
                        context.state.TaxRateLoading = result;
                    },
                });
        },
        PutTaxRate(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/taxrate/${payload.Id}`, dispatch: 'UpdateSingleTaxRate', payload, loading: (result: boolean): void => {
                        context.state.TaxRateLoading = result;
                    },
                });
        },
        DeleteTaxRate(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/taxrate/${payload}`, dispatch: 'GetTaxRates', loading: (result: boolean): void => {
                        context.state.TaxRateLoading = result;
                    },
                });
        },
        GetBillingHistory(context, payload) {
            return context.dispatch('GetCommon',
                {
                    url: `/api/Accounts/myaccount/paymenthistory`,
                    dispatch: 'UpdatePaymentHistory', loading: (result: boolean): void => {
                        context.state.BillingLoading = result;
                    },
                });
        },
        GetFacilites(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/Facility', dispatch: 'UpdateFacility', loading: (result: boolean): void => {
                        context.state.FacilityLoading = result;
                    },
                });
        },
        DeleteFacility(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/Facility/${payload}`, dispatch: 'GetFacilites', loading: (result: boolean): void => {
                        context.state.FacilityLoading = result;
                    },
                });
        },
        PostFacility(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/Facility`, dispatch: 'AddFacility', payload, loading: (result: boolean): void => {
                        context.state.FacilityLoading = result;
                    },
                });
        },
        PutFacility(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/Facility/${payload.Id}`, dispatch: 'UpdateSingleFacility',
                    payload, loading: (result: boolean): void => {
                        context.state.FacilityLoading = result;
                    },
                });
        },
        GetAccpetedPaymentMethod(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/ClientAcceptedPayments', dispatch: 'UpdateAccpetedPaymentMethod', loading: (result: boolean): void => {
                        context.state.AccpetedPaymentMethodLoading = result;
                    },
                });
        },
        DeleteAccpetedPaymentMethod(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/ClientAcceptedPayments/${payload}`, dispatch: 'GetAccpetedPaymentMethod',
                    loading: (result: boolean): void => {
                        context.state.AccpetedPaymentMethodLoading = result;
                    },
                });
        },
        PostAccpetedPaymentMethod(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/ClientAcceptedPayments`, dispatch: 'AddAccpetedPaymentMethod',
                    payload, loading: (result: boolean): void => {
                        context.state.AccpetedPaymentMethodLoading = result;
                    },
                });
        },
        PutAccpetedPaymentMethod(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/ClientAcceptedPayments/${payload.Id}`, dispatch: 'UpdateSingleAccpetedPaymentMethod',
                    payload, loading: (result: boolean): void => {
                        context.state.AccpetedPaymentMethodLoading = result;
                    },
                });
        },
        GetShopHours(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/shophours/defaultweek', dispatch: 'UpdateShopHours', loading: (result: boolean): void => {
                        context.state.ShopHoursLoading = result;
                    },
                });
        },
        GetLocalholidays(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/accounts/myaccount/localholidays', dispatch: 'UpdateLocalholidays',
                    loading: (result: boolean): void => {
                        context.state.LocalholidaysLoading = result;
                    },
                });
        },
        GetExceptionShopHours(context) {
            return context.dispatch('GetCommon',
                {
                    url: '/api/shophours/exceptionTradingHours', dispatch: 'UpdateExceptionShopHours',
                    loading: (result: boolean): void => {
                        context.state.ExceptionShopHoursLoading = result;
                    },
                });
        },
        PutDefaultWeek(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/shophours/defaultweek`, dispatch: 'UpdateShopHours', payload, loading: (result: boolean): void => {
                        context.state.ShopHoursLoading = result;
                    },
                });
        },
        PostExceptionHours(context, payload) {
            return context.dispatch('PostCommon',
                {
                    url: `/api/shophours/${payload.Year}/${payload.Month}/${payload.Day}`,
                    dispatch: 'UpdateSingleExceptionHour', payload, loading: (result: boolean): void => {
                        context.state.ShopHoursLoading = result;
                    },
                });
        },

        PutExceptionHours(context, payload) {
            return context.dispatch('PutCommon',
                {
                    url: `/api/shophours/${payload.Year}/${payload.Month}/${payload.Day}`,
                    dispatch: 'UpdateSingleExceptionHour', payload, loading: (result: boolean): void => {
                        context.state.ShopHoursLoading = result;
                    },
                });
        },
        DeleteExceptionHours(context, payload) {
            return context.dispatch('DeleteCommon',
                {
                    url: `/api/shophours/${payload.Year}/${payload.Month}/${payload.Day}`,
                    dispatch: 'GetExceptionShopHours', payload, loading: (result: boolean): void => {
                        context.state.ShopHoursLoading = result;
                    },
                });
        },
        PostChangePassword(context, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`${context.state.baseUrl}/api/authentication/password`, payload,
                    { headers: { authtoken: context.state.authToken } })
                    .then((response) => {
                        resolve(response.data.Id);
                    })
                    .catch((e) => {
                        if (e.response.status === 401) {
                            context.commit('LoggedOut');
                        }
                        reject(e);
                    });
            });
        },
        PostCommon(context, payload) {
            return new Promise((resolve, reject) => {
                payload.loading(true);
                axios.post(context.state.baseUrl + payload.url, payload.payload,
                    { headers: { authtoken: context.state.authToken } })
                    .then((response) => {
                        context.commit(payload.dispatch, response.data);
                        payload.loading(false);
                        resolve(response.data.ExternalId ?? response.data.Id);
                    })
                    .catch((e) => {
                        payload.loading(false);
                        if (e.response.status === 401) {
                            context.commit('LoggedOut');
                        }
                        reject(e);
                    });
            });
        },
        GetCommon(context, payload) {
            return new Promise((resolve, reject) => {
                payload.loading(true);
                axios.get(context.state.baseUrl + payload.url,
                    { headers: { authtoken: context.state.authToken } })
                    .then((response) => {
                        context.commit(payload.dispatch, response.data);
                        payload.loading(false);
                        resolve(null);
                    })
                    .catch((e) => {
                        payload.loading(false);
                        if (e.response.status === 401) {
                            context.commit('LoggedOut');
                        }
                        reject(e);
                    });
            });
        },
        PutCommon(context, payload) {
            return new Promise((resolve, reject) => {
                payload.loading(true);
                axios.put(context.state.baseUrl + payload.url, payload.payload,
                    { headers: { authtoken: context.state.authToken } })
                    .then((response) => {
                        context.commit(payload.dispatch, response.data);
                        payload.loading(false);
                        resolve(response.data.ExternalId ?? response.data.Id);
                    })
                    .catch((e) => {
                        payload.loading(false);
                        if (e.response.status === 401) {
                            context.commit('LoggedOut');
                        }
                        reject(e);
                    });
            });
        },
        DeleteCommon(context, payload) {
            return new Promise((resolve, reject) => {
                payload.loading(true);
                axios.delete(context.state.baseUrl + payload.url,
                    { headers: { authtoken: context.state.authToken } })
                    .then(() => {
                        context.dispatch(payload.dispatch);
                        payload.loading(false);
                        resolve(null);
                    })
                    .catch((e) => {
                        payload.loading(false);
                        if (e.response.status === 401) {
                            context.commit('LoggedOut');
                        }
                        reject(e);
                    });
            });
        },
    },
});

