






































































import { Vue } from 'vue-property-decorator';
export default Vue.extend({
  data() {
    return {
      goToOptions: {
        duration: 500,
        offset: -110,
        easing: 'easeInOutCubic',
      },
    };
  },
});
