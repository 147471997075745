import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import langwrap from '../views/languagewrap.vue';
import i18n from '../i18n';
import { Trans } from '@/plugins/trans';
Vue.use(VueRouter)

const routes = [
    {
        path: '/:lang/',
        component: langwrap,
        beforeEnter(to: any, from: any, next: any) {
            const lang = to.params.lang;
            if (!Trans.isLangSupported(lang)) {
                return next(Trans.getUserSupportedLang());
            }
            if (i18n.locale === lang) {
                if (!i18n.te("HomeTitle")) {
                    import(`@/locales/${lang}.json`).then((msg) => {
                        i18n.setLocaleMessage(lang, msg.default || msg);
                        i18n.locale = lang;
                        Trans.setI18nLanguageInServices(lang);

                    });
                }


                return next();
            }
            import(`@/locales/${lang}.json`).then((msg) => {
                i18n.setLocaleMessage(lang, msg.default || msg);
                i18n.locale = lang;
                Trans.setI18nLanguageInServices(lang);

                return next();
            });
        },
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home
            },
            {
                path: 'SignUpSalonDetails',
                name: 'SignUpSalonDetails',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "SalonDetails" */ '../components/signup/SalonDetails.vue'),
            },
            {
                path: 'SignUpPersonalDetails',
                name: 'SignUpPersonalDetails',
                component: () => import(/* webpackChunkName: "SalonDetails" */ '../components/signup/PersonalDetails.vue'),
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashBoard.vue'),
            },
            {
                path: 'SignUpPackage',
                name: 'SignUpPackage',
                component: () => import(/* webpackChunkName: "SalonDetails" */ '../components/signup/SignUpPackage.vue'),
            },
            {
                path: 'customers',
                name: 'Customers',
                component: () => import(/* webpackChunkName: "Customer" */ '../views/Customer/CustomersList.vue'),
            },
            {
                path: 'customers/:id',
                name: 'CustomerView',
                component: () => import(/* webpackChunkName: "Customer" */ '../views/Customer/CustomerView.vue'),
            },
            {
                path: 'customers/:id/personal',
                name: 'CustomerPersonal',
                component: () => import(/* webpackChunkName: "Customer" */ '../views/Customer/CustomerPersonal.vue'),
            },
            {
                path: 'customers/:id/edit',
                name: 'CustomerEdit',
                component: () => import(/* webpackChunkName: "Customer" */ '../views/Customer/CustomerEdit.vue'),
            },
            {
                path: 'staff',
                name: 'Staff',
                component: () => import(/* webpackChunkName: "Staff" */ '../views/Staff/StaffList.vue'),
            },
            {
                path: 'staff/:id',
                name: 'StaffView',
                component: () => import(/* webpackChunkName: "Staff" */ '../views/Staff/StaffPersonal.vue'),
            },
            {
                path: 'staff/:id/edit',
                name: 'StaffEdit',
                component: () => import(/* webpackChunkName: "Staff" */ '../views/Staff/StaffEdit.vue'),
            },
            {
                path: 'calendar',
                name: 'Calendar',
                component: () => import(/* webpackChunkName: "Calendar" */ '../views/Calendar.vue'),
            },
            {
                path: 'calendar/staff/:staffId',
                name: 'CalendarStaff',
                component: () => import(/* webpackChunkName: "Calendar" */ '../views/Calendar.vue'),
            },
            {
                path: 'services',
                name: 'Services',
                component: () => import(/* webpackChunkName: "Services" */ '../views/Service/ServicesList.vue'),
            },
            {
                path: 'services/:id/view',
                name: 'ServiceView',
                component: () => import(/* webpackChunkName: "Services" */ '../views/Service/ServiceView.vue'),
            },
            {
                path: 'services/:id/edit',
                name: 'ServiceEdit',
                component: () => import(/* webpackChunkName: "Services" */ '../views/Service/ServiceEdit.vue'),
            },
            {
                path: 'products',
                name: 'Products',
                component: () => import(/* webpackChunkName: "Products" */ '../views/Product/ProductList.vue'),
            },
            {
                path: 'product/:id/edit',
                name: 'ProductEdit',
                component: () => import(/* webpackChunkName: "Products" */ '../views/Product/ProductEdit.vue'),
            },
            {
                path: 'product/:id/view',
                name: 'ProductView',
                component: () => import(/* webpackChunkName: "Products" */ '../views/Product/ProductView.vue'),
            },
            {
                path: 'productservicegroups',
                name: 'ProductServiceGroup',
                component: () => import(/* webpackChunkName: "ProductServiceGroup" */ '../views/ProductServiceGroup/ProductServiceGroupList.vue'),
            },
            {
                path: 'taxrates',
                name: 'TaxRates',
                component: () => import(/* webpackChunkName: "TaxRates" */ '../views/TaxRates/TaxRateList.vue'),
            },
            {
                path: 'Login',
                name: 'Login',
                component: Home,
            },
            {
                path: 'editShopHours',
                name: 'EditShopHours',
                component: () => import(/* webpackChunkName: "ShopHours" */ '../views/ShopHours/ShopHoursList.vue'),
            },
            {
                path: 'exceptionShopHoursList',
                name: 'ExceptionShopHoursList',
                component: () => import(/* webpackChunkName: "ShopHours" */ '../views/ShopHours/ExceptionShopHoursList.vue'),
            },
            {
                path: 'exceptionShopHoursList/view/:year/:month/:day',
                name: 'ExceptionShopHoursListView',
                component: () => import(/* webpackChunkName: "ShopHours" */ '../views/ShopHours/ExceptionShopHoursList.vue'),
            },
            {
                path: 'facilities',
                name: 'Facilities',
                component: () => import(/* webpackChunkName: "Facilities" */ '../views/Facility/FacilityList.vue'),
            },
            {
                path: 'calendar/facility/:facilityId',
                name: 'CalendarFacility',
                component: () => import(/* webpackChunkName: "Calendar" */ '../views/Calendar.vue'),
            },
            {
                path: 'salonprofile/edit',
                name: 'SalonProfileEdit',
                component: () => import(/* webpackChunkName: "SalonProfile" */ '../views/SalonProfile/SalonProfileEdit.vue'),
            },
            {
                path: 'salonprofile/billing',
                name: 'SalonProfileBilling',
                component: () => import(/* webpackChunkName: "SalonProfileBilling" */ '../views/SalonProfile/BillingEdit.vue'),
            },
            {
                path: 'salonprofile/billing/ChangePlan',
                name: 'ChangePlanBilling',
                component: () => import(/* webpackChunkName: "SalonProfileBilling" */ '../views/SalonProfile/ChangePlan.vue'),
            },
            {
                path: 'salonprofile/AcceptedPaymentMethods',
                name: 'AcceptedPaymentMethodsList',
                component: () => import(/* webpackChunkName: "Paymentment" */ '../views/AcceptedPaymentMethods/AcceptedPaymentMethodsList.vue'),
            },
            {
                path: 'checkout/Cart',
                name: 'CheckOutCart',
                component: () => import(/* webpackChunkName: "CheckOutCart" */ '../views/Checkout/Cart.vue'),
            },
        ]
    },
    {
        // Redirect user to supported lang version.
        path: '*',
        redirect() {
            return Trans.getUserSupportedLang();
        },
    },

];

const router = new VueRouter({
    routes, mode: 'history'
})

export default router