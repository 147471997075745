



































import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      goToOptions: {
        duration: 500,
        offset: -90,
        easing: 'easeInOutCubic',
      },
    };
  },
  methods: {
    openSignUpModal() {
      this.$store.commit('openSignUpModal', true);
    },
  },
});
