import Vue from 'vue';

export default Vue.extend({
    methods: {
        redirectToLogin() {
            const lang = this.$route.params.lang;
            const to = this.$router.resolve({
                name: 'login',
                params: { lang },
            });
            this.$router.push(to.location);
        },
    },
    computed: {
        IsAuthenticated(): boolean {
            return this.$store.state.isAuthenticated;
        },
        CanEditAccount(): boolean {
            if (this.$store.state.user.Owner || this.$store.state.user.Manager) {
                return true;
            }
            return false;
        },
        CurrentUser(): any {
            return this.$store.state.user;
        },
        getUserFullName(): string {
            if (this.IsAuthenticated) {
                return (
                    `${this.$store.state.user.FirstName} ${this.$store.state.user.SurName}`
                );
            } else {
                return '';
            }
        },
    },
    watch: {
        IsAuthenticated() {
            if (!this.IsAuthenticated) {
                this.redirectToLogin();
            }
        },
    },
});
