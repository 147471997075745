



















































































































































import { Vue } from 'vue-property-decorator';
import LoadingOverlay from '../common/LoadingOverlay.vue';



export default Vue.extend({
  components: {
    LoadingOverlay,
  },
  computed: {
    currencyPackages(): any {
      return this.$store.state.currencyPackages;
    },
    currencyPackagesLoaded(): boolean {
      return this.$store.state.currencyPackagesLoaded;
    },
    PricesInMessage(): string {
      if (!this.currencyPackagesLoaded) {
        return '';
      }
      if (this.currencyPackages === '') {
        return '';
      }
      return this.$i18n.t('PRICEIN').toString().replace('###', this.currencyPackages[0].CurrencyName);
    },
  },
});
