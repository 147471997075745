



















import { Component, Vue } from 'vue-property-decorator';
import JumboTron from '@/components/homepage/JumboTron.vue';
import DoMore from '@/components/homepage/DoMore.vue';
import GetStarted from '@/components/homepage/GetStarted.vue';
import AccountPackages from '@/components/homepage/AccountPackage.vue';
import SignUpPersonalDetails from '@/components/signup/PersonalDetails.vue';

@Component({
  components: {
    DoMore,
    JumboTron,
    GetStarted,
    AccountPackages,
    SignUpPersonalDetails,
  },
})
export default class Home extends Vue { }
