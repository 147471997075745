











































































































import { Vue } from 'vue-property-decorator';
import GeneralError from '@/modals/GeneralError.vue';

export default Vue.extend({
  data() {
    return {
      form: {
        NewPassword: '',
        CurrentPassword: '',
      },
      showPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      OpenGeneralError: false,
      passwordConfirmation: '',
      Loading: false,
      open: false,
      rules: {
        required: (v: string) => !!v || this.$i18n.t('REQUIRED'),
        email: (v: string) =>
          /.+@.+/.test(v) || this.$i18n.t('EMAIL_VALID'),
        passwordRegex: (v: string) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{3,}$/.test(v) ||
          this.$i18n.t('PASSWORD_PATTERN'),
        passwordmin: (v: string) =>
          v.length >= 6 || this.$i18n.t('PASSWORD_MIN'),
      },
    };
  },
  components: {
    GeneralError,
  },
  props: {
    openDialog: Boolean,
  },
  watch: {
    openDialog() {
      this.open = this.openDialog
    }
  },
  methods: {
    passwordMatchError() {
      return this.form.NewPassword === this.passwordConfirmation
        ? ''
        : this.$i18n.t('REPEAT_PASSWORD_MATCH');
    },
    ClosePasswordChangeModal() {
      (this.$refs.PasswordChange as any).reset();
      this.$emit('ClosePasswordChange');
    },
    closeGeneralError() {
      this.OpenGeneralError = false;
    },
    ChangePassword() {
      if ((this.$refs.PasswordChange as any).validate()) {
        this.Loading = true;
      }
      this.$store
        .dispatch('PostChangePassword', this.form)
        .then(() => {
          this.Loading = false;
          (this.$refs.PasswordChange as any).reset();
          this.$emit('ClosePasswordChange');
        })
        .catch(() => {
          this.Loading = false;
          this.OpenGeneralError = true;
        });
    },
  },
});
