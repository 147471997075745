import Vue from 'vue';

export default Vue.extend({
    methods: {
        FormattedProductPrice(price: number): string {
            const lang = this.$route.params.lang;
            if (
                this.Account === undefined ||
                this.Account === null
            ) {
                return '';
            }
            if (this.Account.CustomerCurrencyId == undefined) {
                return price.toString();
            }
            else {
                return this.$i18n.n(price, {
                    locale: lang,
                    currency: this.Account.CustomerCurrencyId,
                    currencyDisplay: 'symbol',
                    style: 'currency',
                });
            }
        },
    },
    computed: {
        Account(): any {
            return this.$store.state.Account;
        },
        HasAccount(): boolean {
            if (this.Account !== undefined && this.Account !== null && this.Account !== '') {
                return true;
            }
            return false;
        },
        isAuthenticated(): boolean {
            return this.$store.state.isAuthenticated;
        }
    },
    watch: {
        isAuthenticated() {
            if (this.isAuthenticated) {
                this.$store.dispatch('GetAccount');
                this.$store.dispatch('GetMe');
                this.$store.dispatch('GetProductServiceGroups');
            }
        }
    }
});
