
























import { Vue } from 'vue-property-decorator';
import SignUpPersonalDetails from '@/components/signup/PersonalDetails.vue';

export default Vue.extend({
  components: {
    SignUpPersonalDetails,
  },
  methods: {
    openSignUpModal() {
      this.$store.commit('openSignUpModal', false);
    },
  },
  computed: {
    ShowSignUpModal(): boolean {
      return this.$store.state.ShowSignUpModal;
    },
  },
});
