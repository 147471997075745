import { CreateAccountOptional } from './CreateAccountOptional';

export class CreateAccount {
    public OwnerFirstName!: string;
    public OwnerSurName!: string;
    public OwnerEmail!: string;
    public OwnerMobile!: string;
    public OwnerPassword!: string;
    public CountryId!: string;
    public OwnerCountryCode!: string;
    public OwnerNumber!: string;
    public LegalName!: string;
    public TradingName!: string;
    public TaxNumber!: string;
    public Email!: string;
    public PhoneNumber!: string;
    public TimeZoneId!: string;
    public CustomerCurrencyId!: string;
    public CurrencyId!: string;
    public Address1!: string;
    public Address2!: string;
    public City!: string;
    public State!: string;
    public PostCode!: string;
    public PhoneNumberCountryCode!: string;
    public CurrencyPaymentFrequency!: string;
    public PackageId!: string;
    public PaymentProviderId!: number;
    public WantsTrial!: boolean;
    public Optional!: CreateAccountOptional;
	public LanguageId!: string;

    constructor() {
        this.Optional = new CreateAccountOptional();
        this.WantsTrial = true;
    }
}

