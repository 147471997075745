





























import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  data() {
    return {
      Loading: false,
      open: false,
    };
  },
  methods: {
    closeGeneralError() {
      this.$emit('closeGeneralError');
    },
  },
  watch: {
    openDialog() {
      this.open = this.openDialog
    }
  },
  props: {
    openDialog: Boolean,
  },
});
